import React, { useEffect } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStaticQuery, graphql } from "gatsby"

import style from "./Contact.module.css"

library.add(fab)

const Contact = () => {
  const data = useStaticQuery(graphql`
    query SiteSocialQuery {
      site {
        siteMetadata {
          socialMedia {
            href
            id
          }
        }
      }
    }
  `)

  const social = data.site.siteMetadata.socialMedia

  useEffect(() => {
    // Dynamically load the Featurable script
    const script = document.createElement("script")
    script.src = "https://featurable.com/assets/bundle.js"
    script.defer = true
    script.charset = "UTF-8"
    document.body.appendChild(script)

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <section id="contact" className={`${style.sectionContact}`}>
      <Container fluid>
        <Row className="align-items-center">
          <Col
            md="6"
            className={`${style.contactDetails} ml-auto mr-auto mb-auto mt-auto`}
          >
            {/* Featurable widget */}
            <div
              id="featurable-3fd7bf0b-a3b1-4a6f-a4d2-49430aa9da11"
              data-featurable-async
            ></div>

            {/* Contact details */}
            <h3 style={{ textAlign: "center" }}>Contactos</h3>
            <div className={`${style.social}`}>
              {social.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", `${link.id}`]} />
                </a>
              ))}
            </div>
            <h4 className={`${style.email}`}>
              leonelduarte.medicinachinesa@gmail.com
            </h4>
            <h4 className={`${style.email}`}>+351 968 535 083</h4>
            <h4 className={`${style.email}`}>
              Travessa Serafim da Cruz 23
              <br />
              Edificio Artezé 1º Andar Sala S
              <br /> 4475-670 Castêlo da Maia
            </h4>
            <a href="mailto:leonelduarte.medicinachinesa@gmail.com">
              <Button style={{ margin: "10px 0 0 0" }} color="primary">
                Contactar
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
